import styled from 'styled-components';

export const SiteWrapper = styled.main.attrs({ id: 'site-wrapper' })`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-image: var(--dottedLightBg);
    background-size: 16px 16px;
    opacity: 1;
    z-index: 0;
  }
`;

export const SectionOuter = styled.section`
  position: relative;
  display: flex;
  flex-grow: 1;
  padding: var(--siteYAxisPadd) 0px;
  background-color: ${({ secondarySection }) => secondarySection && 'var(--bgBluePowderColor)'};
`;

export const SectionInner = styled.div`
  position: relative;
  width: 100%;
  max-width: var(--layoutMaxWidth);
  padding: 0px var(--siteXAxisPadd);
  margin: 0px auto;
  @media (min-width: 1200px) {
    width: 98%;
  }
`;

export const SectionHeading = styled.h2`
  font-size: 24px;
  font-family: var(--headingFontSemiBold);
  text-align: center;
  padding-bottom: 24px;
  @media (min-width: 481px) {
    font-size: 28px;
  }
  @media (min-width: 576px) {
    font-size: 32px;
  }
  @media (min-width: 992px) {
    font-size: 34px;
  }
  @media (min-width: 1200px) {
    font-size: 36px;
  }
  @media (min-width: 1400px) {
    font-size: 40px;
  }
  @media (min-width: 1800px) {
    font-size: 42px;
  }
`;

// Feature section styles
export const FeaturesOuterContainer = styled.section`
  position: relative;
  width: 100%;
  max-width: var(--layoutMaxWidth);
  padding: 0px var(--siteXAxisPadd);
  margin: 0px auto;
  @media (min-width: 1200px) {
    width: 98%;
  }
`;

export const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 0px;
  @media (min-width: 992px) {
    flex-direction: ${({ variant }) => (variant === 'leftAligned' ? 'row' : 'row-reverse')};
    gap: 40px;
  }
`;

export const FeatureContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  @media (min-width: 481px) {
    max-width: 90%;
  }
  @media (min-width: 992px) {
    width: 38%;
  }
`;

export const FeatureShortName = styled.h2`
  font-size: 12px;
  font-family: var(--commonFontBold);
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding-bottom: 16px;
  @media (min-width: 481px) {
    font-size: 13px;
  }
`;

export const FeatureHeading = styled.h4`
  color: var(--txtHeadingColor);
  font-size: 24px;
  font-family: var(--headingFontSemiBold);
  padding-bottom: 16px;
  @media (min-width: 481px) {
    font-size: 26px;
  }
  @media (min-width: 576px) {
    font-size: 28px;
  }
  @media (min-width: 992px) {
    font-size: 32px;
  }
  @media (min-width: 1200px) {
    font-size: 34px;
  }
  @media (min-width: 1400px) {
    font-size: 36px;
  }
  @media (min-width: 1800px) {
    font-size: 40px;
  }
`;

export const FeatureDescription = styled.p`
  font-size: 15px;
  padding-bottom: 32px;
  @media (min-width: 481px) {
    font-size: 16px;
  }
`;

export const FeatureAnimationWrapper = styled.div`
  width: 100%;
  min-height: 120px;
  margin: 0px auto;
  @media (min-width: 481px) {
    max-width: 90%;
  }
  @media (min-width: 992px) {
    width: 48%;
    margin: unset;
  }
`;

export const FeatureAnimation = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 1px 4px var(--greyShadowColor);
`;
