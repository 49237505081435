import React, { useState } from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const InputField = styled.input`
  font-size: 14px;
  width: 100%;
  height: 50px;
  color: var(--txtHeadingColor);
  background-color: ${({ disabled }) => (disabled ? 'var(--light)' : '#fff')};
  border: 1px solid var(--greyBorderColor);
  border-radius: var(--buttonRadius);
  padding: var(--inputsYAxisPadd) var(--inputsRightPadd) var(--inputsYAxisPadd) var(--inputsLeftPadd);
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
  :hover {
    outline: none;
    border: ${({ disabled }) => (disabled ? '1px solid var(--greyBorderColor)' : '1px solid var(--bgBrandColor)')};
  }
  :focus {
    outline: none;
    border: ${({ disabled }) => (disabled ? '1px solid var(--greyBorderColor)' : '2px solid var(--bgBrandColor)')};
  }
`;

const TextAreaField = styled.textarea`
  font-size: 14px;
  width: 100%;
  height: 100%;
  color: var(--txtHeadingColor);
  background-color: ${({ disabled }) => (disabled ? 'var(--light)' : '#fff')};
  border: 1px solid var(--greyBorderColor);
  border-radius: var(--buttonRadius);
  padding: var(--inputsYAxisPadd) var(--inputsRightPadd) var(--inputsYAxisPadd) var(--inputsLeftPadd);
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
  resize: none;
  &::-webkit-scrollbar {
    width: 4px;
  }
  :hover {
    outline: none;
    border: ${({ disabled }) => (disabled ? '1px solid var(--greyBorderColor)' : '1px solid var(--bgBrandColor)')};
  }
  :focus {
    outline: none;
    border: ${({ disabled }) => (disabled ? '1px solid var(--greyBorderColor)' : '2px solid var(--bgBrandColor)')};
  }
`;

const DboardTextAreaField = styled.textarea`
  font-size: 14px;
  width: 100%;
  height: calc(100% - 28px);
  color: var(--txtHeadingColor);
  background-color: ${({ disabled }) => (disabled ? 'var(--light)' : '#fff')};
  border: none !important;
  outline: none !important;
  padding: 4px 8px 4px;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
  resize: none;
  &::-webkit-scrollbar {
    width: 4px;
  }
  @media (min-width: 992px) {
    font-size: 15px;
    padding: 6px 12px 6px;
  }
`;

const DboardTextAreaContainer = styled.div`
  position: relative;
  width: 100%;
  height: 280px;
  border: 1px solid var(--greyBorderColor);
  border-radius: var(--buttonRadius);
  overflow: hidden;
  &:has(${DboardTextAreaField}:hover) {
    border: ${({ disabled }) => (disabled ? '1px solid var(--greyBorderColor)' : '1px solid var(--bgBrandColor)')};
  }
  &:has(${DboardTextAreaField}:focus) {
    border: ${({ disabled }) => (disabled ? '1px solid var(--greyBorderColor)' : '2px solid var(--bgBrandColor)')};
  }
  @media (min-width: 576px) {
    height: 310px;
  }
  @media (min-width: 992px) {
    height: 330px;
  }
  @media (min-width: 1800px) {
    height: 350px;
  }
`;

const TextAreaContainer = styled.div`
  position: relative;
  width: 100%;
  height: 140px;
  border-radius: var(--buttonRadius);
  overflow: hidden;
`;

const DboardTextAreaBottomDiv = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 28px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #fff;
`;

const IconInputField = styled.input`
  font-size: 14px;
  width: 100%;
  height: 100%;
  padding: var(--inputsYAxisPadd) var(--inputsRightPadd) var(--inputsYAxisPadd) var(--inputsLeftPadd);
  color: var(--txtHeadingColor);
  background-color: ${({ disabled }) => (disabled ? 'var(--light)' : '#fff')};
  border: none !important;
  outline: none !important;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
`;

const IconWrapper = styled.div`
  height: 100%;
  padding: 0px 10px;
  display: inline-flex;
  align-items: center;
  color: var(--txtGreyColor);
  background: var(--bs-light);
  border-right: 1px solid var(--greyBorderColor);
`;

const IconInputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid var(--greyBorderColor);
  border-radius: var(--buttonRadius);
  overflow: hidden;
  &:has(${IconInputField}:hover) {
    border: ${({ disabled }) => (disabled ? '1px solid var(--greyBorderColor)' : '1px solid var(--bgBrandColor)')};
  }
  &:has(${IconInputField}:focus) {
    border: ${({ disabled }) => (disabled ? '1px solid var(--greyBorderColor)' : '2px solid var(--bgBrandColor)')};
  }
`;

const OTPInputField = styled.input`
  width: 32px;
  height: 32px;
  font-size: 15px;
  color: var(--txtHeadingColor);
  text-align: center;
  background-color: ${({ disabled }) => (disabled ? 'var(--light)' : '#fff')};
  border: 1px solid var(--greyBorderColor);
  border-radius: var(--buttonRadius);
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
  :hover {
    outline: none;
    border: ${({ disabled }) => (disabled ? '1px solid var(--greyBorderColor)' : '1px solid var(--bgBrandColor)')};
  }
  :focus {
    outline: none;
    border: ${({ disabled }) => (disabled ? '1px solid var(--greyBorderColor)' : '2px solid var(--bgBrandColor)')};
  }
  @media (min-width: 320px) {
    width: 35px;
    height: 35px;
  }
  @media (min-width: 360px) {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 481px) {
    width: 45px;
    height: 45px;
  }
`;

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SelectField = styled.select`
  font-size: 14px;
  width: 100%;
  height: 50px;
  color: var(--txtHeadingColor);
  background-color: ${({ disabled }) => (disabled ? 'var(--light)' : '#fff')};
  border: 1px solid var(--greyBorderColor);
  border-radius: var(--buttonRadius);
  padding: var(--inputsYAxisPadd) var(--inputsRightPadd) var(--inputsYAxisPadd) var(--inputsLeftPadd);
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
  :hover {
    outline: none;
    border: ${({ disabled }) => (disabled ? '1px solid var(--greyBorderColor)' : '1px solid var(--bgBrandColor)')};
  }
  :focus {
    outline: none;
    border: ${({ disabled }) => (disabled ? '1px solid var(--greyBorderColor)' : '2px solid var(--bgBrandColor)')};
  }
`;

export function Input({ containerStyle, inputStyle, inputID, type, value, onChange, placeholder, error, required, maxLength, autofocus, disabled, inputRef, isFrom, darkTheme }) {
  return (
    <InputContainer style={containerStyle}>
      <InputField
        id={inputID}
        style={inputStyle}
        type={type || 'text'}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        error={error}
        disabled={disabled}
        autoFocus={autofocus}
        required={required}
        ref={inputRef}
        isFrom={isFrom}
        darkTheme={darkTheme}
      />
    </InputContainer>
  );
}

export const TextArea = ({
  containerStyle,
  inputStyle,
  inputID,
  value,
  onChange,
  placeholder,
  error,
  required,
  maxLength,
  autofocus,
  disabled,
  inputRef,
  isFrom,
  darkTheme,
  rowsNum,
}) => {
  return (
    <TextAreaContainer style={containerStyle}>
      <TextAreaField
        id={inputID}
        style={inputStyle}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        error={error}
        disabled={disabled}
        autoFocus={autofocus}
        required={required}
        ref={inputRef}
        isFrom={isFrom}
        darkTheme={darkTheme}
        rows={rowsNum | 6}
      />
    </TextAreaContainer>
  );
};

export const DboardTextArea = ({
  containerStyle,
  inputStyle,
  inputID,
  value,
  onChange,
  placeholder,
  error,
  required,
  maxLength,
  autofocus,
  disabled,
  inputRef,
  isFrom,
  darkTheme,
}) => {
  const [dboardInputLength, setDboardInputLength] = useState(value.length || 0);

  return (
    <DboardTextAreaContainer style={containerStyle}>
      <DboardTextAreaField
        id={inputID}
        style={inputStyle}
        value={value}
        onChange={onChange}
        onKeyUp={(e) => setDboardInputLength(value.length)}
        placeholder={placeholder}
        maxLength={maxLength || 5000}
        error={error}
        disabled={disabled}
        autoFocus={autofocus}
        required={required}
        ref={inputRef}
        isFrom={isFrom}
        darkTheme={darkTheme}
      />
      <DboardTextAreaBottomDiv>
        <span className="fs-12 d-inline-block">{dboardInputLength} / 5000</span>
      </DboardTextAreaBottomDiv>
    </DboardTextAreaContainer>
  );
};

export const SelectInput = ({ containerStyle, inputStyle, inputID, value, onChange, isFrom, disabled }) => {
  <SelectContainer containerStyle={containerStyle}>
    <SelectField id={inputID} style={inputStyle} value={value} onChange={onChange} isFrom={isFrom} disabled={disabled} />
  </SelectContainer>;
};

export function IconInput({
  containerStyle,
  iconStyle,
  inputStyle,
  inputID,
  type,
  value,
  onChange,
  placeholder,
  error,
  required,
  maxLength,
  autofocus,
  disabled,
  inputRef,
  darkTheme,
  isFrom,
  children,
}) {
  return (
    <IconInputContainer style={containerStyle}>
      <IconWrapper style={iconStyle}>{children}</IconWrapper>
      <IconInputField
        id={inputID}
        style={inputStyle}
        type={type || 'text'}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        error={error}
        disabled={disabled}
        autoFocus={autofocus}
        required={required}
        ref={inputRef}
        isFrom={isFrom}
        darkTheme={darkTheme}
      />
    </IconInputContainer>
  );
}

export const OTPInput = ({ inputStyle, inputID, className, type, value, onChange, error, autofocus, disabled, darkTheme }) => {
  return (
    <OTPInputField
      id={inputID}
      className={className}
      style={inputStyle}
      type={type || 'tel'}
      value={value}
      onChange={onChange}
      maxLength="1"
      error={error}
      disabled={disabled}
      autoFocus={autofocus}
      darkTheme={darkTheme}
    />
  );
};
