import React, { Fragment, useState } from 'react';
import config from '../../../../config';
import {
  HeaderDropdownContainer,
  HeaderDropdownIcon,
  DropdownOuterWrapper,
  // DropdownTitleWrapper,
  DropdownNavsWrapper,
  DropdownNavDiv,
  DropdownNavIcon,
  DropdownNavText,
  DropdownNavDescText,
} from './styles';
import { HeaderNavItem } from '../styles';
import productsData from '../../../../Data/productsData';

export default function HeaderDropdown({ productsDropdown, solutionsDropdown }) {
  const cloudFront = config.cloudFront;
  const [productsDropStatus, setProductsDropStatus] = useState(false);

  const toggleProductsDropdown = () => {
    setProductsDropStatus(!productsDropStatus);
  };

  const closeProductsDropdown = () => {
    setProductsDropStatus(false);
  };

  return (
    <Fragment>
      {productsDropdown ? (
        <HeaderDropdownContainer tabIndex={0} onBlur={closeProductsDropdown}>
          <HeaderNavItem active={productsDropStatus ? 'true' : 'false'} onClick={toggleProductsDropdown}>
            Products
            <HeaderDropdownIcon active={productsDropStatus ? 'true' : 'false'} />
          </HeaderNavItem>

          <DropdownOuterWrapper active={productsDropStatus ? 'true' : 'false'}>
            {/*<DropdownTitleWrapper>Products</DropdownTitleWrapper>*/}
            <DropdownNavsWrapper>
              {productsData.map(({ productID, productIcon, productDisplayName, productPageLink, description }) => {
                return (
                  <DropdownNavDiv
                    key={productID}
                    onClick={() => {
                      closeProductsDropdown();
                      window.location.href = productPageLink;
                    }}
                  >
                    <DropdownNavIcon src={cloudFront + productIcon} alt="" />
                    <div className="no-line-height">
                      <DropdownNavText>{productDisplayName}</DropdownNavText>
                      <DropdownNavDescText>{description}</DropdownNavDescText>
                    </div>
                  </DropdownNavDiv>
                );
              })}
            </DropdownNavsWrapper>
          </DropdownOuterWrapper>
        </HeaderDropdownContainer>
      ) : (
        <HeaderDropdownContainer>
          <HeaderNavItem>Solutions</HeaderNavItem>
          <DropdownOuterWrapper>
            {/*<DropdownTitleWrapper>Solutions</DropdownTitleWrapper>*/}
            <DropdownNavsWrapper>
              {productsData.map(({ productID, productIcon, productDisplayName, productPageLink, productDesc }) => (
                <DropdownNavDiv key={productID} href={productPageLink}>
                  <DropdownNavIcon src={cloudFront + productIcon} alt="" />
                  <div>
                    <DropdownNavText>{productDisplayName}</DropdownNavText>
                    <DropdownNavDescText>{productDesc}</DropdownNavDescText>
                  </div>
                </DropdownNavDiv>
              ))}
            </DropdownNavsWrapper>
          </DropdownOuterWrapper>
        </HeaderDropdownContainer>
      )}
    </Fragment>
  );
}
