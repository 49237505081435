import { signOut } from 'aws-amplify/auth';
import { InitSignOut } from '../Actions';
import { store } from '../Store';

const amplifySignout = async () => {
  try {
    await signOut({ global: true });
    store.dispatch(InitSignOut()); // to restore initial state to redux User object

    window.location.href = '/'; // logout redirection for manual signed user
  } catch (error) {
    console.log('error signing out: ', error);
  }
};

export default amplifySignout;
