import styled from 'styled-components';
import { AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { HiOutlinePlus } from 'react-icons/hi';

export const FAQContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const FAQOuter = styled.div`
  width: 100%;
  max-width: var(--layoutMaxWidth);
  padding: var(--siteYAxisPadd) var(--siteXAxisPadd);
  margin: 0 auto;
  @media (min-width: 1200px) {
    width: 98%;
  }
`;

export const FAQTitle = styled.h2`
  font-family: var(--headingFontSemiBold);
  padding-bottom: 24px;
  display: inline-block;
`;

// accordian
export const AccordionItemContainer = styled(AccordionItem)`
  color: var(--txtDescriptionColor);
  background-color: #fff;
  border-radius: var(--rectangleRadius);
  box-shadow: var(--elevationShadow2);
  margin-bottom: 20px;
  transition: 0.2s;
  :last-child {
    margin-bottom: 0px;
  }
`;

// questions div contaner
export const AccordionTitle = styled(AccordionItemHeading)`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

export const TitleWrapper = styled(AccordionItemButton)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  &[aria-expanded='true'] {
    border-bottom: 1px solid var(--skyBorderColor);
  }
  :focus {
    outline: none;
  }
  @media (min-width: 481px) {
    padding: 20px 24px;
  }
`;

//questions
export const TitleContent = styled.div`
  width: 100%;
  margin-right: 10px;
  font-family: ${({ isexpanded }) => (isexpanded === 'true' ? 'var(--commonFontSemiBold)' : 'var(--commonFontRegular)')};
  font-size: 15px;
  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

export const AccordionExpand = styled(HiOutlinePlus)`
  font-size: 20px;
  transition: transform 0.2s;
  transform: ${({ isexpanded }) => (isexpanded === 'true' ? 'rotateZ(45deg)' : 'rotateZ(0deg)')};
`;

// answers
export const AccordionBody = styled(AccordionItemPanel).attrs({ className: 'accordion__body' })`
  display: grid !important;
  grid-template-rows: 1fr;
  transition: var(--hoverTransitionDuration);
  overflow: hidden;
  &[hidden] {
    display: grid !important;
    grid-template-rows: 0fr !important;
  }
`;

export const AccordionBodyOuter = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const AccordionBodyInner = styled.div`
  padding: 15px 48px 20px 20px;
  font-size: 14px;
  @media (min-width: 481px) {
    padding: 15px 40px 20px 24px;
  }
  @media (min-width: 576px) {
    font-size: 15px;
  }
`;
