import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import LazyLoad from 'react-lazyload';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import config from '../../config';
import axiosInstance from '../../Utils/interceptedApi';
import { isAuthenticated } from '../../Routes/ProtectedRoute';
import { getCountryCode } from '../../Utils/userLocation';
import { updateUserLocationDetails } from '../../Actions';
import featureOneSvg from '../../Animations/homepage/homepage-skills-feature-02-jan-2025-05-49.lottie';
import featureTwoSvg from '../../Animations/homepage/homepage-doubt-feature-02-jan-2025-05-49.lottie';
import featureThreeSvg from '../../Animations/homepage/homepage-testing-feature-27-dec-2024-08-01.lottie';
import { SiteWrapper } from '../UI/Layout';
import Header from '../UI/Header';
import Banner from './Banner';
import { FeaturesOuterContainer, FeatureWrapper, FeatureContent, FeatureShortName, FeatureHeading, FeatureDescription, FeatureAnimationWrapper } from '../UI/Layout';
import FAQList from '../UI/FAQList';
import SignupInfo from '../UI/SignupInfo';
import Footer from '../UI/Footer';
import SiteButton from '../UI/FormComponents/SiteButton';

const Home = ({ userLocationDetails, updateUserLocationDetails }) => {
  useEffect(() => {
    async function checkAuthentication() {
      if (isAuthenticated()) {
        signedRedirection();
      } else {
        let countryCode = await getCountryCode(userLocationDetails, updateUserLocationDetails);
      }
    }
    checkAuthentication();
  }, []);

  const signedRedirection = () => (window.location.href = '/dashboard');

  const updateLocation = async () => {
    const { userLocationDetails } = this.props;
    let apiPrefix = 'https://2j0t1q3uh1.execute-api.us-east-1.amazonaws.com';
    // let apiPrefix = 'http://localhost:3001';

    try {
      const apiResult = await axiosInstance(apiPrefix).post('/update-user-details', {
        ...userLocationDetails,
        type: 'locationDetails',
      });
      // console.log('location details data -->', apiResult.data);
      return apiResult.data;
    } catch (error) {
      console.log('usr_dtl: ', error.message);
      return null;
    }
  };

  const signupLink = '/signup';

  return (
    <Fragment>
      <SiteWrapper>
        <Header />
        <Banner signupLink={signupLink} />

        <FeaturesOuterContainer>
          <FeatureWrapper>
            <FeatureAnimationWrapper>
              <LazyLoad offset={200}>
                <DotLottieReact
                  className="d-flex feature-card-radius feature-card-shadow overflow-hidden feature-canvas-wrapper"
                  src={featureOneSvg}
                  loop
                  autoplay
                  useFrameInterpolation={false}
                />
              </LazyLoad>
            </FeatureAnimationWrapper>

            <FeatureContent>
              <FeatureShortName>
                <span className="txt-brand-color">Skill-Based Learning Path</span>
              </FeatureShortName>
              <FeatureHeading>
                Skill evaluation & personalized <span className="d-inline-block">learning path</span>
              </FeatureHeading>
              <FeatureDescription>
                Leyaa.ai evaluates your skills and creates a customized learning journey just for you. Receive specific recommendations and resources that align with your unique
                learning needs.
              </FeatureDescription>
              <SiteButton type={'feature-btn'} href={signupLink}>
                Get started
              </SiteButton>
            </FeatureContent>
          </FeatureWrapper>

          <FeatureWrapper variant={'leftAligned'}>
            <FeatureAnimationWrapper>
              <LazyLoad offset={200}>
                <DotLottieReact
                  className="d-flex feature-card-radius feature-card-shadow overflow-hidden feature-canvas-wrapper"
                  src={featureTwoSvg}
                  loop
                  autoplay
                  useFrameInterpolation={false}
                />
              </LazyLoad>
            </FeatureAnimationWrapper>
            <FeatureContent>
              <FeatureShortName>
                <span className="txt-brand-color">Instant AI Help</span>
              </FeatureShortName>
              <FeatureHeading>
                Instant doubt resolution <span className="d-inline-block d-lg-block">with AI</span>
              </FeatureHeading>
              <FeatureDescription>
                With Leyaa.ai, your doubts are resolved instantly, whenever they arise. No more waiting or disrupting your flow—just uninterrupted learning, at your pace.
              </FeatureDescription>
              <SiteButton type={'feature-btn'} href={signupLink}>
                Explore now
              </SiteButton>
            </FeatureContent>
          </FeatureWrapper>

          <FeatureWrapper>
            <FeatureAnimationWrapper>
              <LazyLoad offset={200}>
                <DotLottieReact
                  className="d-flex feature-card-radius feature-card-shadow overflow-hidden feature-canvas-wrapper"
                  src={featureThreeSvg}
                  loop
                  autoplay
                  useFrameInterpolation={false}
                />
              </LazyLoad>
            </FeatureAnimationWrapper>

            <FeatureContent>
              <FeatureShortName>
                <span className="txt-brand-color">Ace with Practice</span>
              </FeatureShortName>
              <FeatureHeading>Prepare smarter with tests & mock interviews</FeatureHeading>
              <FeatureDescription>
                Prepare smarter with Leyaa.ai's interactive tests and mock interviews. Gain valuable insights instantly, track your progress, and fine-tune your skills to perform
                at your best.
              </FeatureDescription>
              <SiteButton type={'feature-btn'} href={signupLink}>
                Start free
              </SiteButton>
            </FeatureContent>
          </FeatureWrapper>
        </FeaturesOuterContainer>

        {/*<button className="p-2 btn btn-primary common-btn-radius" onClick={() =>updateLocation()} style={{ width: '240px' }}>Update location details</button>*/}
        <FAQList />
        {!isAuthenticated() ? <SignupInfo /> : null}
        <Footer />
      </SiteWrapper>
    </Fragment>
  );
};

const mapStateToProps = ({ User }) => ({
  userLocationDetails: User.locationDetails,
  country: User.locationDetails && User.locationDetails.country,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserLocationDetails: (locationDetails) => dispatch(updateUserLocationDetails(locationDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
