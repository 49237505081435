const productsData = [
  {
    productID: 'coding-interview-prepartion-with-ai',
    pathID: 'coding-interview-prepartion-with-ai',
    productPrimaryName: 'Code Interview Preparation',
    productDisplayName: 'Code interview preparation',
    productIcon: '/Assets/productIcons/coding-interview-preparation-with-ai-icon.svg',
    productPageLink: '/coding-interview-prepartion-with-ai',
    productDesc: 'AI coding interview preparation',
    description: 'Seamlessly code, debug, optimize, and test.',
  },
  {
    productID: 'ai-mock-test-platform',
    pathID: 'ai-mock-test-platform',
    productPrimaryName: 'Mock Test Platform',
    productDisplayName: 'Mock test platform',
    productIcon: '/Assets/productIcons/ai-mock-test-platform-icon.svg',
    productPageLink: '/ai-mock-test-platform',
    productDesc: 'AI mock test companion',
    description: 'Create mock tests, FAQs, and questions instantly',
  },
  {
    productID: 'ai-question-generator',
    pathID: 'ai-question-generator',
    productPrimaryName: 'AI Question Generator',
    productDisplayName: 'AI question generator',
    productIcon: '/Assets/productIcons/ai-question-generator-icon.svg',
    productPageLink: '/ai-question-generator',
    productDesc: 'AI question generator',
    description: 'Quickly generate questions, FAQs, and more.',
  },

  // {
  //   productID: 'super-learner',
  //   pathID: 'super-learner',
  //   productPrimaryName: 'SuperLearner',
  //   productDisplayName: 'Superlearner',
  //   productIcon: '/Assets/productIcons/superlearner.svg',
  //   productPageLink: '/superlearner',
  //   productDesc: 'AI learning companion',
  // },
];

export default productsData;
