import { Amplify } from 'aws-amplify';
import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import config from '../config';

const amplifyConfig = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: config.cognito.userPoolId,
        userPoolClientId: config.cognito.userPoolClientId,
        identityPoolId: config.cognito.identityPoolId,
        loginWith: {
          // OPTIONAL - Hosted UI configuration
          oauth: {
            domain: 'auth.leyaa.ai',
            scopes: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
            redirectSignIn: ['http://localhost:3000/signin', 'https://leyaa.ai/signin', 'http://localhost:3000/signup', 'https://leyaa.ai/signup'],
            redirectSignOut: ['http://localhost:3000/', 'https://leyaa.ai/'],
            responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
          },
        },
      },
    },
  });
  cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());
};

// You can get the current config object
export default amplifyConfig;
