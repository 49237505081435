import React from 'react';
import LazyLoad from 'react-lazyload';
import styled, { keyframes } from 'styled-components';
import SiteButton from '../FormComponents/SiteButton';

const TextColorChange = keyframes`
  0% {
    color: #fff;
  }
  // 5% {
  //   color: #d0e0da;
  // }
  // 10% {
  //   color: #a0c1b5;
  // }
  // 15% {
  //   color: #71a18f;
  // }
  // 20% {
  //   color: #41826a;
  // }
  7% {
    color: #b0cbc1;
  }
  14% {
    color: #619783;
  }  
  21% {
    color: #126345;
  } 
  50% {
    color: #4d4d4d;
  } 
  75% {
    color: #212121;
  } 
  100% {
    color: #fff;
  }
`;

const SignupInfoOuter = styled.div`
  position: relative;
  width: 100%;
  max-width: var(--layoutMaxWidth);
  padding: 16px var(--siteXAxisPadd) 32px;
  margin: 0 auto;
  @media (min-width: 992px) {
    padding: 20px var(--siteXAxisPadd) 40px;
  }
  @media (min-width: 1200px) {
    width: 98%;
  }
`;

const SignupInfoCard = styled.div`
  position: relative;
  width: 100%;
  min-height: 250px;
  padding: 40px var(--siteXAxisPadd);
  border-radius: 24px;
  box-shadow: var(--elevationShadow2);
  overflow: hidden;
  @media (min-width: 992px) {
    padding: 50px var(--siteXAxisPadd);
  }
`;

const SignupInfoBgWrapper = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  user-select: none;
`;

const SignupInfoContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const SignupInfoTitle = styled.h2`
  font-size: 26px;
  font-family: var(--headingFontSemiBold);
  color: #fff;
  animation: ${TextColorChange} 12s infinite ease-in-out;
  @media (min-width: 481px) {
    font-size: 28px;
  }
  @media (min-width: 576px) {
    font-size: 30px;
  }
  @media (min-width: 992px) {
    font-size: 34px;
  }
  @media (min-width: 1200px) {
    font-size: 36px;
  }
  @media (min-width: 1400px) {
    font-size: 40px;
  }
  @media (min-width: 1800px) {
    font-size: 42px;
  }
`;

const SignupInfoPara = styled.p`
  font-size: 16px;
  color: #fff;
  animation: ${TextColorChange} 12s infinite ease-in-out;
  @media (min-width: 481px) {
    font-size: 18px;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
  }
`;

export default function SignupInfo() {
  return (
    <LazyLoad style={{ width: '100%', height: '100%' }}>
      <SignupInfoOuter>
        <SignupInfoCard>
          <SignupInfoBgWrapper>
            {/*<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stopColor="#635AFF">
                      <animate
                        attributeName="stop-color"
                        values="#635AFF;#18D681;#1BB9FF;#FFE056;#635AFF"
                        dur="12s"
                        repeatCount="indefinite"
                        keyTimes="0; 0.25; 0.5; 0.75; 1"
                        calcMode="spline"
                        keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1"
                      />
                    </stop>
                    <stop offset="100%" stopColor="#635AFF">
                      <animate
                        attributeName="stop-color"
                        values="#635AFF;#18D681;#1BB9FF;#FFE056;#635AFF"
                        dur="12s"
                        repeatCount="indefinite"
                        keyTimes="0; 0.25; 0.5; 0.75; 1"
                        calcMode="spline"
                        keySplines="0.25 0.1 0.25 1; 0.25 0.1 0.25 1; 0.25 0.1 0.25 1; 0.25 0.1 0.25 1"
                      />
                    </stop>
                  </linearGradient>

                  <pattern id="dots" x="7" y="7" width="16" height="16" patternUnits="userSpaceOnUse">
                    <circle cx="1" cy="1" r="1" fill="#ffffff" opacity="0.4" />
                  </pattern>
                </defs>
                <rect x="0" y="0" width="100%" height="100%" fill="url(#gradient)" />
                <rect x="0" y="z" width="100%" height="100%" fill="url(#dots)" />
              </svg>*/}

            <svg width="100%" height="100vh" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" stopColor="#635AFF">
                    <animate
                      attributeName="stop-color"
                      values="#635AFF;#18D681;#1BB9FF;#FFE056;#635AFF"
                      dur="12s"
                      repeatCount="indefinite"
                      keyTimes="0; 0.25; 0.5; 0.75; 1"
                      calcMode="spline"
                      keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1"
                    />
                  </stop>
                  <stop offset="100%" stopColor="#635AFF">
                    <animate
                      attributeName="stop-color"
                      values="#635AFF;#18D681;#1BB9FF;#FFE056;#635AFF"
                      dur="12s"
                      repeatCount="indefinite"
                      keyTimes="0; 0.25; 0.5; 0.75; 1"
                      calcMode="spline"
                      keySplines="0.25 0.1 0.25 1; 0.25 0.1 0.25 1; 0.25 0.1 0.25 1; 0.25 0.1 0.25 1"
                    />
                  </stop>
                </linearGradient>

                <pattern id="dots" x="0" y="12" width="16" height="16" patternUnits="userSpaceOnUse">
                  <circle cx="1" cy="1" r="1" fill="#ffffff" opacity="0.4">
                    <animate
                      attributeName="fill"
                      values="#ffffff; #126345; #4d4d4d; #212121; #ffffff"
                      dur="12s"
                      repeatCount="indefinite"
                      keyTimes="0; 0.21; 0.5; 0.75; 1"
                      calcMode="linear"
                    />
                  </circle>
                </pattern>
              </defs>

              <rect x="0" y="0" width="100%" height="100%" fill="url(#gradient)" />
              <rect x="0" y="0" width="100%" height="100%" fill="url(#dots)" />
            </svg>
          </SignupInfoBgWrapper>

          <SignupInfoContent>
            <div className="text-center">
              <SignupInfoTitle>Ready to get started?</SignupInfoTitle>
              <SignupInfoPara>
                Create an account to explore Leyaa.ai <span className="d-inline-block">with a free trial.</span>
              </SignupInfoPara>
            </div>

            <SiteButton type={'feature-btn'} href={'/signup'} btnArrow={'true'} theme={'white'} style={{ height: '50px', color: 'var(--txtBlackColor)' }}>
              Signup now
            </SiteButton>
          </SignupInfoContent>
        </SignupInfoCard>
      </SignupInfoOuter>
    </LazyLoad>
  );
}
