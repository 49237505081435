import React from 'react';
import './App.css';
import 'react-loading-skeleton/dist/skeleton.css';
import amplifyConfig from './Utils/amplifyConfig';
import AppRoute from './Routes/index';

amplifyConfig();

function App() {
  return <AppRoute />;
}

export default App;
