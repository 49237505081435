import React, { Fragment } from 'react';
import { isAuthenticated } from '../../../Routes/ProtectedRoute';
import './styles/index.css';
import { SiteHeaderDarkIcon } from '../SiteIcon';
import {
  FooterOuter,
  FooterInner,
  FooterWrapper,
  FooterTopDivision,
  FooterLogoDivision,
  FooterLogoWrapper,
  FooterContentWrapper,
  FooterLinksWrapper,
  FooterTitle,
  FooterLinks,
  FooterBottomDivision,
  NewsletterDiv,
  SocialsDiv,
  SocialLinks,
  FooterText,
} from './styles';
import SignupInfo from '../SignupInfo';
import { FaFacebookF, FaXTwitter, FaLinkedinIn, FaInstagram, FaYoutube } from 'react-icons/fa6';
import { Input } from '../FormComponents/Inputs';
import SiteButton from '../FormComponents/SiteButton';

const Footer = () => {
  return (
    <FooterOuter>
      <FooterInner>
        <FooterTopDivision id="ftr-logo-content">
          <FooterLogoDivision>
            <FooterLogoWrapper href={isAuthenticated() ? '/dashboard' : '/'}>
              <SiteHeaderDarkIcon />
            </FooterLogoWrapper>

            <FooterText>
              © 2025 Leyaa. <span className="d-inline-block">All rights reserved.</span>
            </FooterText>
          </FooterLogoDivision>

          <div className="d-flex">
            <SocialLinks>
              <FaFacebookF />
            </SocialLinks>
            <SocialLinks>
              <FaXTwitter />
            </SocialLinks>
            <SocialLinks>
              <FaLinkedinIn />
            </SocialLinks>
            <SocialLinks>
              <FaInstagram />
            </SocialLinks>
            <SocialLinks>
              <FaYoutube />
            </SocialLinks>
          </div>
        </FooterTopDivision>
        {/*<hr className="w-100 my-4" />*/}

        <FooterContentWrapper>
          <FooterLinksWrapper id="ftr-products-content">
            <FooterTitle>Products</FooterTitle>

            <span className="mb-1">
              <FooterLinks href="/coding-interview-prepartion-with-ai">Coding Interview Prepation</FooterLinks>
            </span>
            <span className="mb-1">
              <FooterLinks href="/ai-question-generator">AI Question Generator</FooterLinks>
            </span>
            <span className="mb-1">
              <FooterLinks href="/ai-mock-test-platform">Mock Test Platform</FooterLinks>
            </span>
          </FooterLinksWrapper>

          <FooterLinksWrapper id="ftr-legal-content">
            <FooterTitle>Legal</FooterTitle>

            <span className="mb-1">
              <FooterLinks href="/terms">Terms of Service</FooterLinks>
            </span>
            <span className="mb-1">
              <FooterLinks href="/privacy-policy">Privacy Policy</FooterLinks>
            </span>
            <span className="mb-1">
              <FooterLinks href="/fair-usage-policy">Fair Usage Policy</FooterLinks>
            </span>
            <span className="mb-1">
              <FooterLinks href="/refund-policy">Refund Policy</FooterLinks>
            </span>
          </FooterLinksWrapper>

          <FooterLinksWrapper id="ftr-company-content">
            <FooterTitle>Company</FooterTitle>

            <span className="mb-1">
              <FooterLinks href="/contact">Contact Us</FooterLinks>
            </span>
            <span className="mb-1">
              <FooterLinks href="/blog">Blog</FooterLinks>
            </span>
          </FooterLinksWrapper>

          <FooterLinksWrapper id="ftr-links-content">
            <FooterTitle>Links</FooterTitle>

            <span className="mb-1">
              <FooterLinks href="/pricing">Pricing</FooterLinks>
            </span>
          </FooterLinksWrapper>
        </FooterContentWrapper>

        <FooterBottomDivision id="ftr-bottom-content">
          <NewsletterDiv>
            <div className="w-100">
              <label className="cursor-pointer pb-2" htmlFor={'ftr-news-ltr'}>
                <FooterText>
                  <span className="common-font-medium text-dark">Submit your email to stay updated on Leyaa.ai</span>
                </FooterText>
              </label>
              <Input type={'email'} inputID={'ftr-news-ltr'} placeholder={'Email Address'} inputStyle={{ height: '50px', fontSize: '15px' }} />
            </div>
            <SiteButton variant={'border'} style={{ maxWidth: '220px', height: '50px', fontFamily: 'var(--commonFontSemiBold)' }}>
              Subscribe To Newsletter
            </SiteButton>
          </NewsletterDiv>

          <p className="fs-12 pt-4">©2025 Leyaa.ai</p>
          <p className="fs-12">We use cookies and other data collection technologies to provide the best experience for our customers.</p>
        </FooterBottomDivision>
      </FooterInner>
    </FooterOuter>
  );
};

export default Footer;
