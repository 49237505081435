import { INIT_SIGNOUT, UPDATE_CHECKOUT_PLAN, UPDATE_PAID_DETAILS } from '../Actions/actionTypes';

const INIT_STATE = {
  checkoutPlan: {},
  payeeInfo: {
    email: '',
    currencySymbol: '',
    paidAmount: 0,
  },
};

const Checkout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_SIGNOUT:
      return { ...INIT_STATE };

    case UPDATE_CHECKOUT_PLAN:
      return {
        ...state,
        checkoutPlan: action.details,
      };

    case UPDATE_PAID_DETAILS:
      return {
        ...state,
        payeeInfo: action.details,
      };

    default:
      return state;
  }
};

export default Checkout;
