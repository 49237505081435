import styled, { keyframes } from 'styled-components';

const gradientTextEffect = keyframes`
  0% {
    background-position: 200%;
  }
  100% {
    background-position: 0%;
  }
`;

export const HomeBannerOuter = styled.section`
  position: relative;
  display: flex;
  flex-grow: 1;
  // &:after {
  //   position: absolute;
  //   content: '';
  //   width: 100%;
  //   height: 30%;
  //   bottom: 0;
  //   background: var(--lightBorderColor);
  // }
  // @media (min-width: 481px) {
  //   &:after {
  //     height: 35%;
  //   }
  // }
  // @media (min-width: 768px) {
  //   &:after {
  //     height: 40%;
  //   }
  // }
  // @media (min-width: 992px) {
  //   &:after {
  //     height: 45%;
  //   }
  // }
`;

export const HomeBannerInner = styled.div`
  width: 100%;
  max-width: var(--layoutMaxWidth);
  padding: var(--siteYAxisPadd) var(--siteXAxisPadd);
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
`;

export const HomePrimaryContent = styled.div`
  margin-bottom: 40px;
  @media (min-width: 576px) {
    width: 90%;
  }
  @media (min-width: 768px) {
    margin: 0px auto 40px;
    text-align: center;
  }
  @media (min-width: 992px) {
    width: 85%;
  }
  @media (min-width: 1200px) {
    width: 80%;
  }
`;

export const HomeLogo = styled.img`
  width: 210px;
  margin-bottom: 8px;
  @media (min-width: 576px) {
    width: 230px;
  }
  @media (min-width: 992px) {
    width: 250px;
  }
  @media (min-width: 1200px) {
    width: 280px;
  }
  @media (min-width: 1400px) {
    width: 300px;
  }
`;

export const HomeHeading = styled.h1`
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: 10px;
  padding-bottom: 16px;
  font-size: 34px;
  line-height: 1.3;
  @media (min-width: 481px) {
    font-size: 38px;
  }
  @media (min-width: 768px) {
    justify-content: center;
    font-size: 40px;
  }
  @media (min-width: 1200px) {
    font-size: 44px;
  }
  @media (min-width: 1400px) {
    font-size: 48px;
  }
  @media (min-width: 1800px) {
    font-size: 50px;
  }
`;

export const HighlightedHeadText = styled.span`
  position: relative;
  display: inline-block;
  // color: var(--txtBrandColor);
  // background-image: linear-gradient(to right top, #635bff, #0093ff, #00b4ff, #00c9c1, #19d681);
  // background-image: linear-gradient(50deg, #714dff, #9c83ff 31.28%, #e151ff 77.97%, #fff759 95.64%);
  // background-image: linear-gradient(45deg, #635bff, #0093ff 35%, #00b4ff, 52%, #00c9c1 78%, #19d681 90%);
  // background-image: linear-gradient(45deg, #635bff 25%, #0093ff 45%, #00b4ff 65%, #19d681 90%);
  // -ms-background-clip: text;
  // -webkit-background-clip: text;
  // background-clip: text;
  // -webkit-text-fill-color: transparent;
  // background-size: 200%;
  // background-position: 200%;
  // animation: ${gradientTextEffect} 5s linear infinite;
`;

export const HighlightedHeadIcon = styled.span`
  position: absolute;
  top: -6px;
  right: -20px;
  width: 18px;
  height: 18px;
  display: flex;
  @media (min-width: 768px) {
    width: 20px;
    height: 20px;
  }
  @media (min-width: 1200px) {
    width: 22px;
    height: 22px;
  }
  @media (min-width: 1800px) {
    width: 24px;
    height: 24px;
  }
`;

export const ButtonContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

export const HomeSecondaryContent = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0px auto;
  text-align: center;
  @media (min-width: 768px) {
    max-width: unset;
    width: 90%;
  }
  @media (min-width: 992px) {
    width: 80%;
  }
  @media (min-width: 1200px) {
    width: 75%;
  }
`;

export const HomeBannerLogosWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0px auto;
  text-align: center;
  padding-top: 32px;

  @media (min-width: 768px) {
    max-width: unset;
    width: 90%;
  }
  @media (min-width: 992px) {
    width: 80%;
  }
  @media (min-width: 1200px) {
    width: 75%;
  }
`;

export const PackagesBannerInner = styled.div`
  width: 100%;
  max-width: var(--layoutMaxWidth);
  padding: var(--siteYAxisPadd) var(--siteXAxisPadd);
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
`;

export const PackagesPrimaryContent = styled.div`
  margin-bottom: 40px;
  @media (min-width: 768px) {
    width: 80%;
    margin: 0px auto 40px;
    text-align: center;
  }
  @media (min-width: 992px) {
    width: 75%;
  }
  @media (min-width: 1200px) {
    width: 70%;
  }
`;

export const PackagesIcon = styled.img`
  width: 22px;
  margin-right: 4px;
  margin-bottom: 4px;
`;

export const PackagesTitle = styled.p`
  font-size: 13px;
  font-family: var(--commonFontMedium);
  color: var(--txtHeadingColor);
  line-height: normal;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  padding-bottom: 16px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 1400px) {
    font-size: 15px;
  }
`;

export const PackagesHeading = styled.h1`
  font-size: 34px;
  padding-bottom: 16px;
  line-height: 1.2;
  @media (min-width: 481px) {
    font-size: 38px;
  }
  @media (min-width: 768px) {
    font-size: 42px;
  }
  @media (min-width: 1200px) {
    font-size: 46px;
  }
  @media (min-width: 1400px) {
    font-size: 50px;
  }
  @media (min-width: 1800px) {
    font-size: 54px;
  }
`;

export const PackagesSecondaryContent = styled.div`
  max-width: 850px;
  margin: 0px auto;
  text-align: center;
  @media (min-width: 768px) {
    max-width: unset;
    width: 90%;
  }
  @media (min-width: 992px) {
    width: 85%;
  }
  @media (min-width: 1200px) {
    width: 80%;
  }
`;

export const PackagesVideoWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  // background: #fff;
  // box-shadow: 0px 3px 8px 4px var(--greyShadowColor);
  // & svg {
  //   transform: none !important;
  // }
`;

export const PackagesBannerLogosWrapper = styled.div`
  max-width: 850px;
  padding-top: 32px;
  margin: 0px auto;
  text-align: center;
  @media (min-width: 768px) {
    max-width: unset;
    width: 90%;
  }
  @media (min-width: 992px) {
    width: 85%;
  }
  @media (min-width: 1200px) {
    width: 80%;
  }
`;

export const BannerDescriptionText = styled.p`
  font-size: 16px;
  color: var(--bs-dark);
  padding-bottom: 24px;
  @media (min-width: 481px) {
    font-size: 17px;
  }
  @media (min-width: 1400px) {
    font-size: 18px;
  }
`;
