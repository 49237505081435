import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { thunk } from 'redux-thunk';
import User from '../Reducers/user';
import Notification from '../Reducers/notification';
import Checkout from '../Reducers/checkout';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ history: createBrowserHistory() });

const rootReducer = combineReducers({ router: routerReducer, User, Notification, Checkout });

const persistConfig = { key: 'root', storage };
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(routerMiddleware).concat(thunk),
  devTools: process.env.NODE_ENV === 'production' ? false : true, // thus disabled in production
});

export let persistor = persistStore(store);
export const history = createReduxHistory(store);
