import React, { useState } from 'react';
import { Accordion } from 'react-accessible-accordion';
import { FAQContainer, FAQOuter, AccordionItemContainer, AccordionTitle, AccordionBody, TitleWrapper, TitleContent, AccordionExpand } from './styles';
import { SectionHeading } from '../Layout';

export default function FAQList({ darkTheme, isFrom, faqType }) {
  const [activeIndex, setActiveIndex] = useState('');
  const handleClick = (index) => {
    if (activeIndex === -1) {
      setActiveIndex(index);
    } else if (activeIndex === index) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(index);
    }
  };

  // FAQ data
  const defaultData = [
    {
      question: 'What exactly is Leyaa.ai?',
      answer:
        'Leyaa.ai is an adaptive AI platform designed to personalize learning experiences. It evaluates your skills, provides tailored learning paths, and offers instant doubt resolution. Additionally, Leyaa.ai helps you sharpen your abilities through custom tests and mock interviews, enabling you to master skills like coding and more.',
    },
    {
      question: 'Do you provide a free trial?',
      answer: `Yes, Leyaa.ai provides a free plan. You can check out our <a class = "underline-link" href="/pricing">plans</a>.`,
    },
    {
      question: 'What is your refund policy?',
      answer: `We provide a 7-day no-question-asked refund policy. You can read our <a class = "underline-link" href="/refund-policy">refund policy</a>.`,
    },
    {
      question: 'What happens if I cancel my subscription? Will I lose access immediately or at the end of the subscription period?',
      answer: `<p class='pb-2'>No, you will not lose access immediately. You will retain access until the end of the subscription period you paid for.</p>
      <p class='pb-2'>For example, if you paid for a monthly subscription, you will continue to have access to your paid membership benefits until 30 days from the date you made the payment, even if you cancel at any point during that period.</p>
      <p>Similarly, if you paid for a yearly subscription, you will continue to have access to your paid membership benefits until 12 months from the date you made the payment, even if you cancel at any point during that year.</p>`,
    },
    {
      question: 'Can I access Leyaa.ai  on all devices?',
      answer: 'Yes, Leyaa.ai is designed to work seamlessly across all devices, including desktops, laptops, tablets, and smartphones.',
    },
    {
      question: `My question isn't listed here. What should I do?`,
      answer: `If you couldn't find an answer to your question, you can reach out to our customer support team for assistance. You can send an email to <a class = "underline-link" href="mailto:support@leyaa.ai">support@leyaa.ai</a>, and our team will be happy to help you.`,
    },
  ];

  // let data;
  // if (faqType === 'pricing') {
  //   data = pricingData;
  // } else {
  //   data = defaultData;
  // }

  return (
    <FAQContainer isFrom={isFrom} darkTheme={darkTheme ? 'true' : 'false'}>
      <FAQOuter isFrom={isFrom}>
        <div className="text-center">
          <SectionHeading>Frequently Asked Questions</SectionHeading>
        </div>

        <Accordion allowZeroExpanded={true}>
          {defaultData.map(({ question, answer }, index) => (
            <AccordionItemContainer key={question} darkTheme={darkTheme ? 'true' : 'false'} isexpanded={activeIndex === index ? 'true' : 'false'} uuid={index}>
              <AccordionTitle onClick={() => handleClick(index)} onKeyDown={() => handleClick(index)}>
                <TitleWrapper>
                  <TitleContent darkTheme={darkTheme ? 'true' : 'false'} isexpanded={activeIndex === index ? 'true' : 'false'}>
                    {question}
                  </TitleContent>
                  <AccordionExpand isexpanded={activeIndex === index ? 'true' : 'false'} darkTheme={darkTheme ? 'true' : 'false'} />
                </TitleWrapper>
              </AccordionTitle>
              <AccordionBody darkTheme={darkTheme ? 'true' : 'false'} dangerouslySetInnerHTML={{ __html: answer }}></AccordionBody>
            </AccordionItemContainer>
          ))}
        </Accordion>
      </FAQOuter>
    </FAQContainer>
  );
}
