import styled from 'styled-components';

export const FooterOuter = styled.footer`
  position: relative;
  background: var(--footerBgColor);
`;

export const FooterInner = styled.div`
  width: 100%;
  max-width: var(--layoutMaxWidth);
  margin: 0 auto;
  padding: 40px var(--siteXAxisPadd);
  @media (min-width: 992px) {
    padding: 50px var(--siteXAxisPadd);
  }
  @media (min-width: 1200px) {
    width: 98%;
  }
`;

export const FooterTopDivision = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 40px;
  @media (min-width: 481px) {
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
  }
`;

export const FooterLogoDivision = styled.div`
  display: inline-block;
`;

export const FooterLogoWrapper = styled.a`
  width: 24px;
  height: 24px;
  display: inline-flex;
  margin-bottom: 16px;
`;

export const FooterContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-wrap: wrap;
  @media (min-width: 481px) {
    max-height: 250px;
  }
  @media (min-width: 768px) {
    max-height: 160px;
    align-content: space-between;
  }
  // @media (min-width: 1200px) {
  //   max-height: 200px;
  // }
  // @media (min-width: 1400px) {
  //   max-height: 160px;
  // }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterTitle = styled.h4`
  font-family: var(--commonFontMedium);
  padding-bottom: 12px;
  @media (min-width: 992px) {
    font-size: 17px;
  }
  @media (min-width: 1400px) {
    font-size: 18px;
  }
`;

export const FooterLinks = styled.a`
  font-size: 14px;
  &:hover {
    color: var(--txtBrandColor);
  }
  @media (min-width: 992px) {
    font-size: 15px;
  }
  @media (min-width: 1400px) {
    font-size: 16px;
  }
`;

export const FooterBottomDivision = styled.div`
  padding-top: 40px;
`;

export const NewsletterDiv = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 16px;
  @media (min-width: 768px) {
    max-width: 780px;
    flex-direction: row;
    align-items: end;
  }
`;

export const SocialsDiv = styled.div`
  width: 100%;
  max-width: 280px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const SocialLinks = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--txtHeadingColor);
  text-decoration: none;
  margin-right: 20px;
  cursor: pointer;
  transition: var(--hoverTransitionDuration);
  &:last-child {
    margin-right: initial;
  }
  &:hover {
    color: var(--txtBrandColor);
  }
  @media (min-width: 992px) {
    font-size: 20px;
  }
`;

export const FooterText = styled.p`
  display: block;
  font-size: 14px;
  @media (min-width: 992px) {
    font-size: 15px;
  }
  @media (min-width: 1400px) {
    font-size: 16px;
  }
`;
