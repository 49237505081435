import styled from 'styled-components';

export const HeaderContainer = styled.header`
  position: sticky;
  top: 0;
  z-index: 3000;
  height: var(--headerHeight);
  padding-top: ${({ headerScrolling }) => headerScrolling && 'var(--headerSeperationSpace)'};
  transition: padding-top var(--layoutTransitionDuration);
`;

export const HeaderOuter = styled.div`
  height: 100%;
  width: 100%;
  max-width: var(--layoutMaxWidth);
  padding: 0px var(--siteXAxisPadd);
  margin: 0px auto;
  @media (min-width: 1200px) {
    width: 98%;
  }
`;

export const HeaderInner = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ headerScrolling }) => (headerScrolling ? '#fff' : 'none')};
  border-radius: var(--rectangleRadius);
  box-shadow: ${({ headerScrolling }) => (headerScrolling ? 'var(--elevationShadow1)' : 'none')};
  transition: var(--layoutTransitionDuration);
`;

export const HeaderLogoWrapper = styled.div`
  width: 95px;
  display: block;
  user-select: none;
  margin-bottom: 4px;
`;

export const HeaderIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: block;
  user-select: none;
`;

export const HeaderNavWrapper = styled.nav`
  height: 100%;
  display: none;
  margin-left: 20px;
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
  }
`;

export const HeaderNavItem = styled.div.attrs({ className: 'head-nav-drop-item' })`
  height: 40px;
  padding: 5px 10px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  color: var(--txtHeadingColor);
  line-height: 1.75;
  font-size: var(--navFontSize);
  font-family: var(--commonFontMedium);
  cursor: pointer;
  background-color: ${({ active }) => active === 'true' && 'var(--greyBgLight)'};
  border-radius: var(--buttonRadius);
  // &:after {
  //   content: '';
  //   height: 2px;
  //   width: 0%;
  //   background: var(--bgBrandColor);
  //   position: absolute;
  //   left: 24px;
  //   left: 50%;
  //   bottom: 0px;
  //   transform: translateX(-50%);
  //   transition: width var(--hoverTransitionDuration);
  // }
  &:hover {
    background-color: var(--greyBgLight);
  }
`;

export const HeaderNavLink = styled.a`
  height: 40px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  color: var(--txtHeadingColor);
  line-height: 1.75;
  font-size: var(--navFontSize);
  font-family: var(--commonFontMedium);
  &:hover {
    color: var(--txtBrandColor);
    // background-color: var(--lightBorderColor);
  }
  // &:after {
  //   content: '';
  //   height: 2px;
  //   width: 0%;
  //   background: var(--bgBrandColor);
  //   position: absolute;
  //   left: 24px;
  //   left: 50%;
  //   bottom: 0px;
  //   transform: translateX(-50%);
  //   transition: width var(--hoverTransitionDuration);
  // }
  // &:hover&:after,
  // &:active&:after {
  //   width: 100%;
  // }
`;

export const HeaderSecondaryLink = styled.a`
  color: var(--txtHeadingColor);
  font-size: var(--navFontSize);
  font-family: var(--commonFontMedium);
  line-height: 1.75;
  &:hover {
    color: var(--txtBrandColor);
  }
`;

export const HeaderCTAWrapper = styled.div`
  display: none;
  @media (min-width: 481px) {
    display: flex;
    align-items: center;
  }
`;

export const SideNavToggler = styled.div`
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: var(--txtHeadingColor);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--borderColor);
  cursor: pointer;
`;
