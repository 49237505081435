import styled from 'styled-components';
import { HiChevronDown } from 'react-icons/hi';

// large courses start
export const HeaderDropdownContainer = styled.div`
  display: none;
  :focus {
    outline: none;
  }
  @media (min-width: 992px) {
    position: relative;
    display: inline-block;
  }
`;

export const HeaderDropdownIcon = styled(HiChevronDown)`
  font-size: 17px;
  margin-top: 2px;
  transform: ${({ active }) => active === 'true' && 'rotate(180deg)'};
  transition: 0.1s;
`;

export const DropdownOuterWrapper = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: ${({ active }) => active === 'true' && 'block'};
    position: absolute;
    top: 60px;
    left: 0px;
  }
`;

export const DropdownTitleWrapper = styled.div`
  text-align: center;
  color: white;
  background: linear-gradient(to right, var(--bgBrandColor), #7465ff, #836fff, #9079ff, var(--bgBrandLightColor));
`;

export const DropdownNavsWrapper = styled.div`
  width: 700px;
  height: 420px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 20px;
  padding: var(--siteXAxisPadd);
  background-color: white;
  border-radius: var(--rectangleRadius);
  box-shadow: var(--elevationShadow2);
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: block;
  }
`;

export const DropdownNavDiv = styled.div`
  width: 100%;
  max-width: 300px;
  padding: 20px;
  background-color: #fff;
  border-radius: var(--cardRadius);
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background-color: var(--greyBgLight);
  }
  &:hover p {
    color: var(--txtBrandColor);
  }
`;

export const DropdownNavIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 12px;
`;

export const DropdownNavText = styled.p`
  color: var(--txtHeadingColor);
  font-family: var(--commonFontMedium);
  font-size: 16px;
  line-height: normal;
  padding-bottom: 4px;
`;

export const DropdownNavDescText = styled.span`
  color: var(--txtDescriptionColor);
  font-size: 14px;
`;
