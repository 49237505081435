/* eslint-disable */
import React from 'react';
import { ReactComponent as SiteFormSvg } from '../../../Icons/leyaa-header-logo.svg';
import { ReactComponent as SiteHeaderSvg } from '../../../Icons/leyaa-header-icon.svg';
import { ReactComponent as SiteHeaderSvgDark } from '../../../Icons/leyaa-header-dark-icon.svg';
import { ReactComponent as SparkleIcon } from '../../../Icons/sparkle-icon.svg';

export function SiteFormLogo() {
  return <SiteFormSvg />;
}

export function SiteHeaderIcon() {
  return <SiteHeaderSvg />;
}

export function SiteHeaderDarkIcon() {
  return <SiteHeaderSvgDark />;
}

export function SiteSparkleIcon() {
  return <SparkleIcon />;
}
